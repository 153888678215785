/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700;800;900&display=swap');

@font-face {
    font-family: 'Donalia';
    src: url('../../fonts/Donalia.eot');
    src: url('../../fonts/Donalia.eot?#iefix') format('embedded-opentype');
    src: url('../../fonts/Donalia.woff2') format('woff2');
    src: url('../../fonts/Donalia.woff') format('woff');
    src: url('../../fonts/Donalia.ttf')  format('truetype');
}
@font-face {
    font-family: 'Scriptina';
    src: url('../../fonts/scriptina.regular.ttf') format('truetype');
    //src: url('../../fonts/Donalia.eot?#iefix') format('embedded-opentype');
    //src: url('../../fonts/Donalia.woff2') format('woff2');
    //src: url('../../fonts/Donalia.woff') format('woff');
    //src: url('../../fonts/Donalia.ttf')  format('truetype');
}

// fonts
$base-font-size: 15;
$base-font: 'Jost', sans-serif;
//$heading-font: 'Donalia';
$heading-font: 'Scriptina';


$dusty-blue: #8296a9;
$dark-blue: #263953;
$burgundy: #2d0e10;
$rose: #c99994;
$light-rose: #9a8482;
$faint: $burgundy;

// color
//$dark-gray: #06002E;
$dark-gray: $dusty-blue;
//$body-color: #525252;
$body-color: $rose;
$white: #fff;
//$white: #33ff33;
$light: #8188a9;
$black: #000;
$small-black: #333;
$cyan: #525252;

$amazon: #ff9900;

//$theme-primary-color: #2c2c2c;
$theme-primary-color: $dusty-blue;
$theme-primary-color-s2: #DFCACC;
//$body-bg-color: #fff;
//$body-bg-color: #682a2a;
//$body-bg-color: $rose;
$body-bg-color: $faint;
$section-bg-color: #fbf8f8;
//$section-bg-color: #287c60;
$text-color: #585858;
$text-light-color: #7b7b7b;
//$heading-color: $dark-gray;
$heading-color: $dusty-blue;
$border-color: #ebebeb;
$border-color-s2: #e4ebf2;
$site-header-bg-color: $dark-blue;